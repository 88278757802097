import React, { useRef } from 'react'
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Navbar from "./navbar"
import Footer from "./footer"
import "./layout.scss"

//import ModelCoffee from "./modelCoffee"
import BackgroundVideoMp4 from "../video/bg.mp4"
import BackgroundVideoWebm from "../video/bg.webm"

//import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
//import coffee from '../../static/coffee.glb'
/*
async function CoffeeBox() {
  const loader = new GLTFLoader();

  const loadedData = await loader.loadAsync('./coffee.glb');
  //const gltf = useLoader(GLTFLoader, './coffee.gltf')
  return <primitive object={loadedData.scene} position={[0, 0, 0]} />
}
*/




const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const videoRef= useRef();
  const setPlayBack = () => {
    //videoRef.current.playbackRate = 0.75;
  };

  return (
    <>
      
      <div className="fullscreen-bg">
        <video className="fullscreen-bg__video" autoPlay muted loop playsInline ref={videoRef} onCanPlay={() => setPlayBack()}>
          <source src={BackgroundVideoMp4} type="video/mp4" />
          <source src={BackgroundVideoWebm} type="video/webm" />
        </video>
      </div>
      <div className="d-flex w-100 h-100 mx-auto flex-column">
        <Navbar siteTitle={data.site.siteMetadata?.title || `Title`} />
        <main className="h-100">{children}</main>
        <Footer saleState={false}/>
      </div>

    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
