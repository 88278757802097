import * as React from "react"
import PropTypes from "prop-types"

import TopTick from "../assets/top-ticks.svg";
import CornerTick from "../assets/corner-tick.svg";


const Navbar = ({ siteTitle }) => {
  return (
    <header className="mb-auto">
      <div className="container-fluid p-0 m-0">
        <div className="row justify-content-md-center g-0">
          <div className="col-1 col-lg-1 border border-start-0 d-flex justify-content-center align-items-center">
            <CornerTick
              width={15.5}
              height={15.5}
              alt="Design Element Tick Lines"
              className="img-fluid my-4"
            />  
          </div>
          <div className="col col-lg-3 border border-start-0 border-end-0 d-none d-lg-flex d-flex justify-content-center align-items-center">
            <TopTick
              width={252}
              height={16}
              alt="Design Element Tick Lines"
              className="img-fluid my-5"
            />  
          </div>
          <div className="col-10 col-lg-4 border d-flex flex-column justify-content-center align-items-center">
            <h6 className="mb-0">A DROP BY</h6>
            <h5 className="">
              <a className="text-decoration-none" href="http://partyround.com">PARTY ROUND</a> X <a className="text-decoration-none" href="http://cometeer.com">COMETEER</a>
            </h5>
          </div>
          <div className="col col-lg-3 border border-start-0 border-end-0 d-none d-lg-flex d-flex justify-content-center align-items-center">
            <TopTick
              width={252}
              height={16}
              alt="Design Element Tick Lines"
              className="img-fluid"
            /> 
          </div>
          <div className="col-1 col-lg-1 border border-end-0 d-flex justify-content-center align-items-center">
            <CornerTick
              width={15.5}
              height={15.5}
              alt="Design Element Tick Lines"
              className="img-fluid  rotate-90"
            />  
          </div>
        </div>
      </div>
    </header>
  )
}

Navbar.propTypes = {
  siteTitle: PropTypes.string,
}

Navbar.defaultProps = {
  siteTitle: ``,
}

export default Navbar
