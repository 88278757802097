/* eslint react/jsx-pascal-case: "off" */

import React, { useState } from 'react'

import Marquee from "react-fast-marquee";

import CornerTick from "../assets/corner-tick.svg";
import TopTick from "../assets/top-ticks.svg";
import Carousel1_1 from "../assets/carousel1_1.svg";
import Carousel1_2 from "../assets/carousel1_2.svg";
import Carousel1_3 from "../assets/carousel1_3.svg";
import Carousel2_1 from "../assets/carousel2_1.svg";
import Carousel2_2 from "../assets/carousel2_2.svg";
import Carousel2_3 from "../assets/carousel2_3.svg";

import ArrowUpRight from "../assets/arrow-up-right.svg";

const Footer = ({ siteTitle, saleState }) => {
  const [isActive, setActive] = useState(false);

  const openPanel = () => {
    setActive(!isActive);
  };

  const pressBuy = () => {
    window.location.href = 'https://cometeer.com/partygrounds';
  };

  const pressTeam = () => {
    window.location.href = 'https://cometeer.com/office';
  };

  return (
    <footer className="mt-auto">
      <div className="container-fluid p-0 m-0">
        <div className="row justify-content-md-center g-0">
          <div className="col-1 col-lg-1 border border-start-0 d-flex justify-content-center align-items-center">
            <CornerTick
              width={15.5}
              height={15.5}
              alt="Design Element Tick Lines"
              className="img-fluid rotate-270 "
            />  
          </div>
          <div className="col col-lg-3 border border-start-0 border-end-0 d-flex align-items-center d-none d-lg-flex">
            <div id="carouselLeft" className="carousel slide  w-100 my-4" data-bs-ride="carousel">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <div className="container ">
                    <div className="row justify-content-md-center ">
                      <div className="col col-lg-3">
                        <Carousel1_1
                          width={32.12}
                          height={55.99}
                          alt="Coffee with heat coming off it"
                          className="img"
                        />  
                      </div>
                      <div className="col-lg-5 ">
                        <h6 className="text-start">HOT COFFEE</h6>
                        <p className="text-start">Combine frozen puck with 6-8 oz of hot water.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="container ">
                    <div className="row justify-content-md-center ">
                      <div className="col col-lg-3">
                        <Carousel1_2
                          width={50.18}
                          height={51.31}
                          alt="Pour capsule into glass with icecubes"
                          className="img"
                        /> 
                      </div>
                      <div className="col-lg-5">
                        <h6 className="text-start">ICED COFFEE</h6>
                        <p className="text-start">Melt contents and pour into 6-8 oz of hot water.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="container ">
                    <div className="row justify-content-md-center ">
                      <div className="col col-lg-3">
                        <Carousel1_3
                          width={53.49}
                          height={54.11}
                          alt="Pour capsule into mug with heat coming off it"
                          className="img"
                        />
                      </div>
                      <div className="col-lg-5">
                        <h6 className="text-start">HOT LATTE</h6>
                        <p className="text-start">Melt contents and then add 6 oz of steamed milked.</p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#carouselLeft" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#carouselLeft" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
          <div className="col-10 col-lg-4 border d-flex justify-content-center align-items-center d-none d-lg-flex flex-column">
            {!saleState && (
              <>
                <div className={"text-danger missed-cta d-flex justify-content-between align-items-center " + (isActive ? "open" : null)} onClick={() => pressTeam()}>
                  <ArrowUpRight
                    width={48}
                    height={48}
                  />
                  <div >Missed Party Grounds? Cometeer can still hook up your entire team with delicious coffee, wherever they are.</div>
                  <ArrowUpRight
                    width={48}
                    height={48}
                  />
                </div>
                <div className="marquee-clickable" onClick={() => openPanel()}>
                  <Marquee gradient={false}>
                    <p className="text-danger mx-3">SOLD OUT</p> 
                    <p className="text-danger mx-3">SOLD OUT</p>
                    <p className="text-danger mx-3">SOLD OUT</p> 
                    <p className="text-danger mx-3">SOLD OUT</p> 
                    <p className="text-danger mx-3">SOLD OUT</p> 
                    <p className="text-danger mx-3">SOLD OUT</p> 
                    <p className="text-danger mx-3">SOLD OUT</p> 
                    <p className="text-danger mx-3">SOLD OUT</p> 
                    <p className="text-danger mx-3">SOLD OUT</p> 
                    <p className="text-danger mx-3">SOLD OUT</p> 
                    <p className="text-danger mx-3">SOLD OUT</p> 
                    <p className="text-danger mx-3">SOLD OUT</p> 
                  </Marquee>
                </div>
              </>
            )}
            {saleState && (
              <button onClick={() => pressBuy()} type="button" className="btn btn-primary btn-lg buy">BUY</button>
            )}
          </div>
          <div className="col-10 border border-start-0 border-end-0 d-lg-none">
            <TopTick
              width={252}
              height={16}
              alt="Design Element Tick Lines"
              className="img-fluid my-3"
            />
          </div>
          <div className="col col-lg-3 border border-start-0 border-end-0 d-flex align-items-center d-none d-lg-flex">
            <div id="carouselRight" className="carousel slide  w-100" data-bs-ride="carousel">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <div className="container ">
                    <div className="row justify-content-md-center ">
                      <div className="col-lg-3">
                        <Carousel2_1
                          width={61}
                          height={61}
                          alt="Coffee bean"
                          className="img"
                        />
                      </div>
                      <div className="col-lg-6 ">
                        <h6 className="text-start">A BETTER CUP</h6>
                        <p className="text-start">Our extraction methods result in a stronger, more flavorful cup.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="container ">
                    <div className="row justify-content-md-center ">
                      <div className="col-lg-3">
                        <Carousel2_2
                          width={62}
                          height={55.71}
                          alt="Cup moving quickly"
                          className="img"
                        />
                      </div>
                      <div className="col-lg-6">
                        <h6 className="text-start">TAKE IT ANYWHERE</h6>
                        <p className="text-start">If you’ve got water and a cup or mug, you can have Cometeer.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <div className="container ">
                    <div className="row justify-content-md-center ">
                      <div className="col col-lg-3">
                        <Carousel2_3
                          width={74}
                          height={47.5}
                          alt="Cup moving quickly"
                          className="img"
                        />
                      </div>
                      <div className="col-lg-6">
                        <h6 className="text-start">HOT LATTE</h6>
                        <p className="text-start">Melt contents and then add 6 oz of steamed milked.</p>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#carouselRight" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#carouselRight" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
          <div className="col col-lg-1 border border-end-0 d-flex justify-content-center align-items-center">
            <CornerTick
              width={15.5}
              height={15.5}
              alt="Design Element Tick Lines"
              className="img-fluid rotate-180"
            />  
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
